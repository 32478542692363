.hover-image-remove-btn{
  position: absolute;
  right: 10%;
  z-index: 5;
}

.hide-btn{
 display: none;
}

.show-btn{
  display: inline-block;
}

.product-image-container{
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.add-products-container{
  max-height: 300px;
  overflow: auto;
}

.add-collection-image-container{
  height: 60px;
  width: 60px;
  border: solid 0.06rem #ddd;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collection-image{
  display: block;
  max-width: 100%;
  width: auto;
  height: 100%;
}

.image-icon{
  width: 60px !important;
  height: 60px !important;
}

@media only screen and (min-width: 900px) and (max-width: 1300px) { 
  .add-collection-image-container{
    width: 40px;
    height: 40px;
  }
}
