.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value);
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
  content: "8px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9px"]::before {
  content: "9px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="11px"]::before {
  content: "11px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
  content: "30px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="46px"]::before {
  content: "36px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before {
  content: "48px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="60px"]::before {
  content: "60px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="72px"]::before {
  content: "72px";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="96px"]::before {
  content: "96px";
}