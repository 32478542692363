.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.gallery-item {
  height: 100%;
  width: 100%;
  position: relative;
  border: solid 1px #C8CCCE;
  border-radius: 5px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: 0 0;
}

.gallery-item-0 {
  grid-column-start: span 2;
  grid-row-start: 1;
  grid-row-end: 3;
  aspect-ratio: 2/2;
}

.gallery-item-1 {
  grid-row-start: 1;
}

.gallery-item-2 {
  grid-row-start: 1;
}

.gallery-item-3 {
  grid-row-start: 2;
  grid-row-end: 3;
}

.gallery-item-4 {
  grid-row-start: 2;
  grid-row-end: 2;
}

.new-image-container{
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: dotted;
  cursor: pointer;
  aspect-ratio: 1;
}

.image-preview{
  height: 85%;
  width: 62%;
  border-radius: 10%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #EBEBEB;
  cursor: pointer;
  aspect-ratio: 1;
}

.image-display-preview{
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.hover-delete-button{
  position: absolute;
  top: 0;
  left: 2%;
}

.new-image-button{
  color: darkblue;
  background: lightskyblue;
  border: none;
  border-radius: 7px;
}

.new-image-button:hover{
  opacity: 0.7;
}

.image-container{
  max-height: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  background-size: 100% 100% !important;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-absolute-container{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #8a8a8a;
  opacity: 0.9;
  padding: 10px;
  border-radius: 3px;
}

.list-display{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.inner-list-display {
  display: flex;
  align-items: center;
}

.margin-left-components{
  margin-left: 20px;
}

.add-products-container{
  max-height: 300px;
  overflow: auto;
}
